/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

html body {
[dir="rtl"] {
        .p-l-0 {
		    padding-right: 0px;
		}

		.p-l-10 {
		    padding-right: 10px;
		}

		.p-l-20 {
		    padding-right: 20px;
		}

		.p-r-0 {
		    padding-left: 0px;
		}

		.p-r-10 {
		    padding-left: 10px;
		}

		.p-r-20 {
		    padding-left: 20px;
		}

		.p-r-30 {
		    padding-left: 30px;
		}

		.p-r-40 {
		    padding-left: 40px;
		}
        .ml-auto {
		    margin-right: auto;
            margin-left: 0;
		}
		.m-l-5 {
		    margin-right: 5px;
		}

		.m-l-10 {
		    margin-right: 10px;
		}

		.m-l-15 {
		    margin-right: 15px;
		}

		.m-l-20 {
		    margin-right: 20px;
		}

		.m-l-30 {
		    margin-right: 30px;
		}

		.m-l-40 {
		    margin-right: 40px;
		}

		.m-r-5 {
		    margin-left: 5px;
		}

		.m-r-10 {
		    margin-left: 10px;
		}

		.m-r-15 {
		    margin-left: 15px;
		}

		.m-r-20 {
		    margin-left: 20px;
		}

		.m-r-30 {
		    margin-left: 30px;
		}

		.m-r-40 {
		    margin-left: 40px;
		}
	
    .mat-mdc-card .mat-mdc-card-content{
        position: relative;
    }
	.mailbox .message-center a .user-img {
		margin: 0 0 0 10px;
	}
    
    }
}