/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$fa-font-path: "/node_modules/font-awesome/fonts";
@import '/node_modules/font-awesome/scss/font-awesome.scss';
@import "~ng-pick-datetime/assets/style/picker.min.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

* {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0 !important;
}

// Color theme change

.maroon .mat-toolbar.mat-primary {
    background: #19325c !important;
    color: white;
}

.maroon .mat-drawer {
    color: rgba(0, 0, 0, 0.87);
    background-image: linear-gradient(-140deg, #123360, #79282c) !important;
}

.maroon .mat-mdc-button.mat-primary,
.maroon .mat-mdc-icon-button.mat-primary,
.maroon .mat-mdc-outlined-button.mat-primary {
    color: #ffffff !important;
}

// .active-gray-selected {
//     background: #1d426c!important;
// }
.active-selected {
    border-left: 2px solid #860e21 !important;
}

#contact-sidenav {
    font-size: 12px !important;
    padding: 14px;
    margin-top: auto;
    color: #fff !important;
}

.outline:hover {
    background-color: none !important;
}

.my-profile {
    width: 70px;
    margin: 0 auto;
    padding: 15px 5px;
    text-align: center;
}

.role-section {
    font-size: 12px;
    text-transform: uppercase;
}

.hand:hover {
    cursor: pointer;
}

.mdc-text-field {
    padding: 0 !important;
}