p {
    margin: 0;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.dialog-overflow-hidden .mat-mdc-dialog-container {
    overflow-y: hidden !important;
}

button:disabled {
    mat-icon {
        color: rgba(0, 0, 0, 0.26);
    }
}

button:disabled{
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
}

.width-300px{
    min-width: 300px !important;
}