/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use '@angular/material' as mat;
// font family, also paste this import into styles.css file.@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// $bodyfont:'Poppins', sans-serif;
// $headingfont:'Poppins', sans-serif;
// $bodyfont:'Roboto', sans-serif;
// $headingfont:'Roboto', sans-serif; 
$bodyfont: 'Open Sans', sans-serif;
$headingfont: 'Open Sans', sans-serif;

// $bodyfont:'Source Sans Pro', sans-serif;
// $headingfont:'Source Sans Pro', sans-serif; 

@import '@angular/material/theming';
@import './colors/maroon.scss';

/*Material Theme Colors*/

$primary: mat.define-palette($mat-maroon, 600);
$accent: mat.define-palette(mat.$indigo-palette, 500);
$warn: mat.define-palette(mat.$pink-palette, 500);
$success: mat.define-palette(mat.$green-palette, 600);
$theme: mat.define-light-theme((color: (primary: $primary, accent: $accent, warn: $warn)));
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include mat.all-component-themes($theme);


// Dark Theme

/*Theme Colors*/
 
$topbar: #19325c;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c; 
$sidebar-text: #8f999e;
$sidebar-icons: #99abb4 ;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #f8002d;
$success: #0dbd16;
$warning: #ffb22b;
$primary: #860e21;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #dbb7bc;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4; 



$danger-dark: #e6294b;
$success-dark: #298113;
$warning-dark: #e9ab2e;
$primary-dark: #56040b;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

