@use '@angular/material' as mat;
mat.$theme-ignore-duplication-warnings: true;

$mat-maroon: (
    50: #f0e2e4,
    100: #dbb7bc,
    200: #c38790,
    300: #aa5664,
    400: #983242,
    500: #860e21,
    600: #7e0c1d,
    700: #730a18,
    800: #690814,
    900: #56040b,
    A100: #ff888f,
    A200: #ff555f,
    A400: #ff222f,
    A700: #ff0817,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$maroon-app-primary: mat.define-palette($mat-maroon);
$maroon-app-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$maroon-app-warn: mat.define-palette(mat.$pink-palette);
// Create the theme object (a Sass map containing all of the palettes).
$maroon-app-theme: mat.define-light-theme((color: (primary: $maroon-app-primary, accent: $maroon-app-accent, warn: $maroon-app-warn)));
$themecolor: #860e21;
$themecolor-alt: #860e21;
$white: #fff;
#snav {
    .mat-nav-list .mat-mdc-list-item {
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        .mat-list-item-content {
            &:hover {
                > a {
                    color: $themecolor;
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        &.selected > .mat-list-item-content > a {
            background: $themecolor-alt;
            color: $white;
        }
    }
}
