/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use '@angular/material' as mat;
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';


@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';
@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';
@import 'common';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

// Red Theme

// .danger {  
// 	@import 'colors/red';
// 	@include mat.all-component-themes($red-app-theme);
// }

.maroon{
	@import 'colors/maroon';
	@include mat.all-component-themes($maroon-app-theme);
}

// Blue Theme

// .blue { 
// 	@import 'colors/blue'; 
//   	@include mat.all-component-themes($blue-app-theme);
// }

// Green Theme

// .green {  
// 	@import 'colors/green';
//   	@include mat.all-component-themes($green-app-theme);
// }

// Dark Theme

// .dark { 
// 	@import 'colors/dark'; 
//   	@include mat.all-component-themes($dark-theme); 
// }
  
// $font-family: 'Source Sans Pro'
// $font-family: 'Roboto'
// $font-family: 'Poppins'
$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto'
); 
@include mat.all-component-typographies($custom-typography);
@include mat.core();